import React from 'react'
import {graphql, Link} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../../components/layouts/MainLayout'

const metaData = {
  title: "Granite Countertops -  Greenville, SC",
  description: "Granite countertops offer decades of superior performance and impressive durability. Discover the best selection of granite countertops and granite slabs in Greenville."
}

export default function GraniteCountertopsIndex({data}) {
  const products = data.allWpSimpleProduct.edges
  return (
    <Layout metaData={metaData}>
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h1 className="text-primary-600 text-3xl font-extrabold sm:text-4xl">Granite Kitchen and Bathroom Countertops</h1>
              <h2 className="text-gray-700 text-2xl font-extrabold sm:text-3xl">Fabrication & Installation in Greenville, SC</h2>
              <div className="pt-8 space-y-4">
                <h3 className="mb-4 text-gray-500 text-1xl font-extrabold sm:text-2xl">Unmatched Granite Selection</h3>
                <p className="text-xl text-gray-500">
                Greenville Countertops offers a huge granite countertop selection that is unequaled in Greenville. With the largest number of suppliers in the area we provide the best choice of granite slabs. Because we buy large volumes we can pass those savings to our customers.
                </p>
              </div>
            </div>
            
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
              { products.map(({node}) => (
                <li key={node.slug}>
                  <Link to={`/${node.slug}`}>
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <figure className="relative">
                          <GatsbyImage
                            className="h-64"
                            image={node.image.localFile.childImageSharp.gatsbyImageData}
                            alt={node.name}
                          />

                          <div className="cursor-pointer absolute bottom-0 right-0 hover:shadow-outline">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
                            <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            </div>
                          </div>

                        </figure>
                      </div>
                      <figcaption>
                        <div className="space-y-2">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{node.name}</h3>
                          </div>
                        </div>
                      </figcaption>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query All_GRANITE_PRODUCTS {
    allWpSimpleProduct(sort: {fields: name, order: ASC}, filter: {productCategories: {nodes: {elemMatch: {name: {eq: "Granite"}}}}}) {
      edges {
        node {
          name
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`